import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { Attachment, AttachmentDocStash, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  attachments: { items: Attachment[]; totalCount: number };
}

export interface DocStorResponse {
  docStorAttachments: { items: AttachmentDocStash[] };
}

@Injectable({
  providedIn: 'root',
})
export class AttachmentsGQL extends Query<Response, GraphQLParams<'attachment'>> {
  document = gql(config.attachment.query);
}
@Injectable({
  providedIn: 'root',
})
export class AttachmentsDocStorGQL extends Query<DocStorResponse, GraphQLParams<'attachmentDocStor'>> {
  document = gql(config.attachmentDocStor.query);
}
